import { render, staticRenderFns } from "./TableDdlExtends.vue?vue&type=template&id=2b529b1b"
import script from "./TableDdlExtends.vue?vue&type=script&lang=js"
export * from "./TableDdlExtends.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "TableDdlExtends.vue"
export default component.exports